import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {exhaustMap, map, Observable, of, tap} from 'rxjs';
import {ApiResponse} from '../models/ApiResponse';
import {CreateUserRequest} from '../models/requests/CreateUserRequest';
import {User} from '../models/User';
import {UserState} from '../store/user/user.store';
import {Store} from '@ngrx/store';
import {updateUser} from '../store/user/user.actions';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    BASE_URL = `//${environment.domain}/${environment.apiPrefix}/users`;

    constructor(
        private httpClient: HttpClient,
        private store: Store<UserState>
    ) {
    }

    login(email: string, password: string): Observable<User> {
        return this.getCsrfToken().pipe(
            exhaustMap(() =>
                this.httpClient.post<ApiResponse<User>>(`${this.BASE_URL}/login`, {
                    email,
                    password,
                })
            ),
            map(response => response.data!!),
            tap((user: User) => this.store.dispatch(updateUser({user: new User(user)})))
        );
    }

    getMyProfile(): Observable<User> {
        return this.getCsrfToken().pipe(
            exhaustMap(() =>
                this.httpClient.get<ApiResponse<User>>(`${this.BASE_URL}/profile`)
            ),
            map(response => response.data!!),
            tap((user: User) => this.store.dispatch(updateUser({user: new User(user)})))
        );
    }

    register(request: CreateUserRequest): Observable<User> {
        return this.getCsrfToken().pipe(
            exhaustMap(() =>
                this.httpClient.post<ApiResponse<User>>(
                    `${this.BASE_URL}/register`,
                    request
                )
            ),
            map(response => response.data!!),
            tap((user: User) => this.store.dispatch(updateUser({user: new User(user)})))
        );
    }

    logout(): Observable<any> {
        return this.getCsrfToken().pipe(
            exhaustMap(() => this.httpClient.post(`${this.BASE_URL}/logout`, {}))
        )
    }

    private getCsrfToken = () =>
        this.httpClient.get(`//${environment.domain}/${environment.apiPrefix}/csrf`);
}
