import {Nullable} from "../common/types";

export class Campaign {
    id: string;
    short_link: string;
    description: Nullable<string>;
    translations: CampaignTranslations;
    images: CampaignImages;
    donations: Array<Donation>;
    ambassadors: Array<Ambassador>;
    donation_targets: CampaignDonationTargets;
    payment_options: Array<PaymentOption>;
    max_donation_amount: Nullable<number>;
    payment_provider: PaymentProvider;

    status: string;
    currency: string;

    created_at: string;

    constructor(campaign: any) {
        Object.assign(this, campaign)
        this.donations = this.donations.map(d => new Donation(d))
        this.ambassadors = this.ambassadors.map(a => new Ambassador(a))
    }

    isActive = () => this.status === "active"

    getCreatedAt(): Date {
        return new Date(this.created_at);
    }

    getHeaderImg() {
        return this.images.header_img_url || 'assets/images/no-image.png'
    }

    getFeaturedImg() {
        return this.images.logo_img_url || 'assets/images/logos/logo-icon-light-w-bg.jpg'
    }

    getCustomPaymentOption() {
        return this.payment_options.find(p => p.is_custom)
    }

    getNonCustomPaymentOptions() {
        return this.payment_options.filter(p => !p.is_custom)
            .sort((a, b) => a.amount! - b.amount!)
    }

    getHasAmbassadors(): boolean {
        return this.ambassadors?.length > 0
    }

    getAmbassadorDonations(ambassador: Ambassador): Array<Donation> {
        return this.donations.filter(d => d.ambassador_id == ambassador.id)
    }

    getDonationSum = () => this.donations.reduce((crr, donation) => crr + donation.amount, 0);

}

export class MinimizedCampaign {
    id: string;
    short_link: string;
    translations: CampaignTranslations;
    images: CampaignImages;
    donation_amount: number;
    donation_targets: CampaignDonationTargets;

    status: string;
    currency: string;

    created_at: string;

    constructor(campaign: any) {
        Object.assign(this, campaign)
    }

    getCreatedAt(): Date {
        return new Date(this.created_at);
    }

    getFeaturedImg() {
        return this.images.logo_img_url || 'assets/images/logos/logo-icon-light-w-bg.jpg'
    }
}

export interface CampaignImages {
    header_img_url: Nullable<string>;
    logo_img_url: Nullable<string>;
}

export interface CampaignTranslations {
    ar: CampaignTranslation;
    he: CampaignTranslation;
    en: CampaignTranslation;
}

export type CampaignTranslationsKey = 'ar' | 'he' | 'en';

export interface CampaignTranslation {
    title: string;
    sub_title: string;
    about_us: string;
}

export interface CampaignDonationTargets {
    amount: number;
    date: Date;
}

export class Donation {
    ambassador_id: Nullable<number>;
    amount: number;
    date: Date;
    donor_name: Nullable<string>;
    donor_profile_image_url: Nullable<string>;
    message: Nullable<string>;
    currency: string;

    constructor(donation: any) {
        Object.assign(this, donation)
        this.date = new Date(donation.date)
    }

    getDonorImage() {
        return this.donor_profile_image_url || 'assets/images/user-no-face.jpg'
    }
}

export class Ambassador {
    id: number;
    name: string;
    profile_picture_url: Nullable<string>;
    donations_target: Nullable<number>;

    constructor(ambassador: any) {
        Object.assign(this, ambassador)

    }

    getProfilePicture() {
        return this.profile_picture_url || 'assets/images/user-no-face.jpg'
    }
}

export interface PaymentOption {
    id: number;
    image_url: Nullable<string>;
    is_custom: Nullable<boolean>;
    amount: Nullable<number>;
    selected: Nullable<boolean>;
}

export interface PaymentProvider {
    id: string,
    type: string,
    client_id: string
}
