import {Nullable} from "../common/types";

export class User {
  id: number;
  email_verified: boolean;
  role: string;
  name: string;
  email: string;
  phone: Nullable<string>;

  profile_picture_url: Nullable<string>;

  constructor(user: User) {
    return Object.assign(this, user);
  }

  hasProfilePicture = () => this.profile_picture_url != null

  getProfilePicture = () => this.hasProfilePicture() ? this.profile_picture_url : "assets/img/user-no-face.jpg"
}
